<template>
  <div class="page-box box">
    <van-field
      v-model="formData.userName"
      label-align="top"
      :name="formCn.userName"
      :label="formCn.userName"
      :placeholder="formCn.userName"
      :error-message="formData.userName ? '' : ('请输入' + formCn.userName)"
      required
    />
    <van-field
      v-model="formData.childName"
      label-align="top"
      name="学员姓名"
      label="学员姓名"
      placeholder="学员姓名"
      :error-message="formData.childName ? '' : '请输入学员姓名'"
      required
    />
    <van-field
      v-model="formData.mobile"
      label-align="top"
      required
      type="tel"
      name="联系方式"
      label="联系方式"
      placeholder="联系方式"
      :error-message="formData.mobile ? '' : '请输入联系方式'"
    />

    <div
      class="btn pay-btn"
      @click="goPay"
    >
      去支付
    </div>
  </div>
</template>

<script>
export default {
  name: 'Test',
  components: {
  },
  data () {
    return {
      formData: {
        childName: '',
        mobile: '',
        userName: ''
      },
      formCn: {
        childName: '学员姓名',
        mobile: '联系方式',
        userName: '家长姓名'
      }
    }
  },
  computed: {
  },
  created () {
    this.getUser()
  },
  methods: {
    wxPay (data) {
      let vm = this;
      function onBridgeReady(data) {
        WeixinJSBridge.invoke('getBrandWCPayRequest', {
          appId: data.appId || 'wx6815d091476ccf20',  //公众号ID，由商户传入    
          timeStamp: data.timeStamp,   //时间戳，自1970年以来的秒数    
          nonceStr: data.nonceStr,      //随机串    
          package: data.pkg,
          signType: data.signType,     //微信签名方式：    
          paySign: data.paySign //微信签名
        },
        function(res) {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            console.log(res, '支付成功')
            // vm.goHomePage()
            vm.$toast.success('支付成功')
            // window.localStorage.removeItem('payCheckInfo')
            // window.localStorage.removeItem('courseDetail')
            setTimeout(() => {
              vm.$router.back()
            }, 1500)
          }
        });
      }
      if (typeof WeixinJSBridge == 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', onBridgeReady(data));
          document.attachEvent('onWeixinJSBridgeReady', onBridgeReady(data));
        }
      } else {
        onBridgeReady(data);
      }	
    },
    async goPay () {
      let formData = this.formData;
      if (formData.userName && formData.childName && formData.mobile) {
        let data = {
          courseNo: this.$route.query.id || '',
        }
        data = Object.assign(data, formData)
        let res = await this.$http.orderApi.getPayInfo(data)
        // console.oog(res, 'rers')
        if (res.code === 200 && res.msg) {
          this.wxPay(res.msg)
        }
      }
    },
    async getUser () {
      // todo wqs
      let res = await this.$http.homeApi.getUserInfo({
        userId: 0
      })
      if (res.code == 200 && res.msg) {
        this.formData = res.msg || {}
      }
      console.log(res, 'res')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  height: 100%;
  .pay-btn {
    width: 346px;
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-left: -173px;
    background: #4A77FF;
    border-radius: 20px;
    height: 40px;
    color: #fff;
    font-size: 16px;
    line-height: 40px;
    text-align: center;
  }
}
</style>
